import React, { useEffect, useState } from 'react';
import { useBridgeApi, useCurrency } from 'Hooks';
import styled from 'styled-components';
import { ActionAnimations, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import { useShoppingCartApi, useShoppingCart } from 'Components/ShoppingCartUniverse';
import { ReactComponent as TrashIcon } from 'Assets/Icons/trash.svg';
import Icon from 'Components/Icon';
import usei18n from 'i18n';
import useTakeAwayBag from '../../../Hooks/useTakeAwayBag';

const Row = styled.div<{ removed: boolean; grabbing: boolean; disabled: boolean }>`
  width: 100%;
  z-index: 1;
  max-height: ${({ removed }) => (removed ? 0 : 2000)}px;
  transition: max-height 0.3s;
  cursor: ${({ grabbing, disabled }) => {
    if (disabled) {
      return 'initial';
    }

    return grabbing ? 'grabbing' : 'grab';
  }};
`;
const Inner = styled.div`
  width: 100%;
  padding: 0 24px;
  background-color: #fff;
`;
const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #eeeef1;
`;
const Variations = styled.div``;
const Variation = styled.p<{ removeVariation?: boolean }>`
  color: ${({ removeVariation }) => (removeVariation ? '#e3273b' : '#212124')};
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 20px;
  margin-top: 4px;

  span {
    width: 12px;
    margin-right: 4px;
    display: inline-block;
    text-align: center;
  }
`;
const Text = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`;
const Title = styled.p`
  font-weight: 600;
`;
const Price = styled.p`
  min-width: 80px;
  text-align: right;
`;
const DiscountedPrice = styled.div`
  min-width: 80px;
  text-align: right;
`;
const DiscountedPriceAmount = styled.div`
  color: green;
  min-width: 80px;
  text-align: right;
`;
const OriginalPrice = styled.p`
  min-width: 80px;
  text-align: right;
`;
const Delete = styled.div<{ removed: boolean; color: string }>`
  width: 100%;
  background-color: ${({ color }) => color};
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: ${({ removed }) => (removed ? 0 : 1)};
  transition: opacity 0.7s, background-color 0.2s ease-in-out;
`;
const DeleteIconWrapper = styled.div<{ right: number }>`
  position: relative;
  transform: translateX(-${({ right }) => right}px);
  margin-right: -20px;
`;

interface Variation {
  id: string;
  title: string;
  amount: number;
  groupId: string;
}
interface Props {
  /**
   * id
   */
  id: string;
  /**
   * cartItemId
   */
  cartItemId: string;
  /**
   * title
   */
  title: string;
  /**
   * amount
   */
  amount: number;
  /**
   * discount
   */
  discount: number;
  /**
   * variations
   */
  variations: Variation[];
  /**
   * removedVariations
   */
  removedVariations: string[];
  /**
   * threshold
   */
  threshold: number;
  /**
   * offerTitle
   */
  offerTitle: string | { [key: string]: string } | undefined;
}

/**
 * CartItem component
 */

const CartItem: React.FC<Props> = ({
  id,
  title,
  amount,
  discount,
  variations,
  removedVariations,
  cartItemId,
  threshold,
  offerTitle,
}) => {
  const { i18n } = usei18n();
  const shoppingCartApi = useShoppingCartApi();
  const shoppingCart = useShoppingCart();
  const { takeAwayBagItemId, removeTakeAwayBag } = useTakeAwayBag();
  const [removed, setRemoved] = useState(false);
  const [right, setRight] = useState(0);
  const [grabbing, setGrabbing] = useState(false);
  const { formatAmount } = useCurrency();
  let timeout: NodeJS.Timeout;
  const api = useBridgeApi();

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (right > 0) {
      if (!grabbing) {
        setGrabbing(true);
      }
    } else if (grabbing) {
      setGrabbing(false);
    }
  }, [right]);

  const swipeLeftOptions = {
    content: (
      <Delete removed={removed} color={right < 50 ? '#8B8F93' : '#e3273b'}>
        <DeleteIconWrapper right={right}>
          <Icon icon={<TrashIcon />} color={'#fff'} width={26} height={24} />
        </DeleteIconWrapper>
      </Delete>
    ),
    actionAnimation: ActionAnimations.REMOVE,
    action: () => {
      timeout = setTimeout(() => {
        api.vibrate('impactLight');
        shoppingCartApi.removeItem(cartItemId);
      }, 400);
      setRemoved(true);
    },
  };

  const handleChangeProgress = (progress: number) => {
    setRight(progress < 25 ? progress * 2 : 50);
  };

  const disabled = id === takeAwayBagItemId;

  return (
    <SwipeableListItem
      blockSwipe={disabled}
      swipeLeft={swipeLeftOptions}
      threshold={threshold}
      onSwipeProgress={handleChangeProgress}
      onSwipeEnd={() => setRight(0)}>
      <Row removed={removed} grabbing={grabbing} disabled={disabled}>
        <Inner>
          <Content>
            <Text>
              <Title>
                {title}
                {discount > 0 &&
                  (shoppingCart.promoCode?.title ? (
                    <div>
                      <small>{i18n({ id: '1', ...shoppingCart.promoCode.title })}</small>
                    </div>
                  ) : (
                    offerTitle && (
                      <div>
                        <small>{offerTitle}</small>
                      </div>
                    )
                  ))}
              </Title>
              {discount ? (
                <DiscountedPrice>
                  <OriginalPrice>{formatAmount(Number(amount))}</OriginalPrice>
                  <DiscountedPriceAmount>
                    -{formatAmount(Number(discount))}
                    <br />
                  </DiscountedPriceAmount>
                </DiscountedPrice>
              ) : (
                <Price>{formatAmount(Number(amount))}</Price>
              )}
            </Text>
            {Boolean(variations.length) && (
              <Variations>
                {variations.map((item, index) => (
                  <Variation key={index}>
                    <span>+</span>
                    {item.title} {Boolean(item.amount) && ' (' + formatAmount(item.amount) + ')'}
                  </Variation>
                ))}
              </Variations>
            )}
            {Boolean(removedVariations?.length) && (
              <Variations>
                {removedVariations?.map((item, index) => (
                  <Variation key={index} removeVariation>
                    <span>-</span>
                    {item}
                  </Variation>
                ))}
              </Variations>
            )}
          </Content>
        </Inner>
      </Row>
    </SwipeableListItem>
  );
};

export default CartItem;
