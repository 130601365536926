import { useMemo } from 'react';
import styled from 'styled-components';
import { useQuery, gql } from '@apollo/client';
import { SwipeableList } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import Item from './Item';
import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import { GetRemovedVariations, GetRemovedVariationsVariables } from './__queries__';
import useI18n from 'i18n';
import useTakeAwayBag from '../../../Hooks/useTakeAwayBag';

export const REMOVED_VARIATIONS_QUERY = gql`
  query GetRemovedVariations($removedVariationIds: [ID!]) {
    allVariations(filter: { id_in: $removedVariationIds }) {
      id
      name
    }
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  height: auto;
`;

const CartItems = () => {
  const shoppingCart = useShoppingCart();
  const { takeAwayBagItemId } = useTakeAwayBag();
  const { i18n } = useI18n();
  const removedVariationIds = shoppingCart.items.flatMap((item) => item.removedVariations);
  const { data } = useQuery<GetRemovedVariations, GetRemovedVariationsVariables>(
    REMOVED_VARIATIONS_QUERY,
    {
      variables: {
        removedVariationIds,
      },
    },
  );
  const removedVariationsList = data?.allVariations;
  const threshold = 0.3;

  const filteredItems = useMemo(() => {
    // If there is a takeaway bag, move to the end of the list
    const takeAwayBagItem = shoppingCart.items.find((i) => i.itemId === takeAwayBagItemId);
    if (takeAwayBagItem) {
      const itemsWithoutBag = shoppingCart.items.filter((i) => i.itemId !== takeAwayBagItemId);
      return [...itemsWithoutBag, takeAwayBagItem];
    }
    return shoppingCart.items;
  }, [shoppingCart.items]);

  return (
    <Wrapper>
      <SwipeableList threshold={threshold}>
        {filteredItems.map((item) => {
          return (
            <Item
              key={item.id}
              title={item.title}
              offerTitle={item.offerTitle ? i18n(item.offerTitle) : shoppingCart.offer?.title}
              id={item.itemId}
              variations={item.variations}
              amount={item.amount}
              discount={item.discount}
              removedVariations={
                removedVariationsList
                  ?.filter((rv) => item.removedVariations.includes(rv.id))
                  .map((rv) => rv.name) ?? []
              }
              cartItemId={item.id}
              threshold={threshold}
            />
          );
        })}
      </SwipeableList>
    </Wrapper>
  );
};

export default CartItems;
