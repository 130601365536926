import { useMemo } from 'react';
import { useShoppingCart, useShoppingCartApi } from '../Components/ShoppingCartUniverse';
import { useConfig } from '../Components/ConfigProvider';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import useEntryFinder from './useEntryFinder';
import { GetTakeAwayBagItem, GetTakeAwayBagItemVariables } from './__queries__';
import useI18n from '../i18n';

export const companiesWithTakeAwayBag = [
  {
    // Zocalo DK
    companyId: 'clv6nuey94udl0b78yrf7rdj2',
    itemId: 'cm8g3503klrw80817h5qv5gh6',
  },
];

export const GET_TAKE_AWAY_BAG_ITEM = gql`
  query GetTakeAwayBagItem($id: ID!) {
    item: Item(id: $id) {
      id
      title
      vatRate
      disableMembershipDiscount
      categories {
        id
      }
      titleLang {
        id
        se
        en
        es
        no
      }
    }
  }
`;

const useTakeAwayBag = () => {
  const shoppingCartApi = useShoppingCartApi();
  const config = useConfig();
  const findEntryFromItem = useEntryFinder();
  const { i18n } = useI18n();
  const shoppingCart = useShoppingCart();
  const companyId = config.COMPANY_ID;

  const companyWithTakeAwayBag = useMemo(() => {
    return companiesWithTakeAwayBag.find((c) => c.companyId === companyId);
  }, [companyId]);

  const takeAwayBagItemId = companyWithTakeAwayBag?.itemId || '';
  const { data, loading } = useQuery<GetTakeAwayBagItem, GetTakeAwayBagItemVariables>(
    GET_TAKE_AWAY_BAG_ITEM,
    {
      variables: {
        id: takeAwayBagItemId,
      },
      skip: !takeAwayBagItemId,
    },
  );

  const addTakeAwayBag = () => {
    if (shoppingCart.serviceType !== 'TAKE_AWAY') {
      return;
    }

    if (!data || !takeAwayBagItemId) {
      return;
    }

    const alreadyInCart = shoppingCart.items.find((i) => i.itemId === takeAwayBagItemId);

    if (alreadyInCart) {
      return;
    }

    const entry = findEntryFromItem(takeAwayBagItemId);

    if (!entry) {
      return;
    }

    const { item } = data;
    const { categories } = item;
    const categoryId = categories[0] ? categories[0].id : '';

    shoppingCartApi.addItem(
      i18n(item.titleLang),
      entry.item.id,
      entry.id,
      [],
      [],
      entry.price,
      item.disableMembershipDiscount,
      categoryId,
    );
  };

  const removeTakeAwayBag = () => {
    if (shoppingCart.serviceType !== 'TAKE_AWAY') {
      return;
    }

    const { items } = shoppingCart;

    if (items.length > 1) {
      return;
    }

    const takeAwayBagItem = items.find((i) => i.itemId === takeAwayBagItemId);

    if (takeAwayBagItem) {
      shoppingCartApi.clearCart();
    }
  };

  return { loading, addTakeAwayBag, removeTakeAwayBag, takeAwayBagItemId };
};

export default useTakeAwayBag;
