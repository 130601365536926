import React, { useState } from 'react';
import styled from 'styled-components';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import useI18n from 'i18n';
import { useBridgeApi, useDispatch, useLogger, useSelector } from 'Hooks';
import { CountryState, setAlert, setLoginScreen } from 'actions';
import TextField from '../../Components/TextField';
import Button from '../../Components/Button';
import { GetUser } from './__queries__';
import { useStorage } from 'Components/Storage';
import { getDeviceSize, DEVICE_SIZES } from '../../utils/deviceSize';
import { useConfig } from 'Components/ConfigProvider';
import Navbar from '../../Components/Navbar';

const Wrapper = styled.div`
  padding: 120px 24px 88px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Inner = styled.div`
  @media (min-width: 768px) {
    width: 417px;
    margin: 0 auto;
  }
`;

const ForgotPasswordLink = styled(Link)`
  color: #212124;
  float: right;
  text-decoration: underline;

  @media (min-width: 768px) {
    margin: 0 0 24px 0;
  }
`;

export const SIGN_IN_MUTATION = gql`
  mutation login($companyId: ID!, $phone: String!, $password: String!) {
    login(companyId: $companyId, phone: $phone, password: $password) {
      error
      token
    }
  }
`;
const USER_QUERY = gql`
  query GetUser {
    user {
      id
      activated
    }
  }
`;

const rules = [
  {
    name: 'phone',
    message: 'SignUp.Error.Phone',
  },
  {
    name: 'password',
    regexp: /^.{4,}$/,
    message: 'SignUp.Error.Password',
  },
];

export const getPhoneCode = (country: CountryState) => {
  switch (country) {
    case 'SE':
      return '+46';
    case 'GB':
      return '+44';
    case 'NO':
      return '+47';
    case 'ES':
      return '+34';
    case 'DK':
      return '+45';
    case 'TR':
      return '+90';
    default:
      return '+46';
  }
};

const defaultFormData = {
  phone: '',
  password: '',
};

const SignIn = () => {
  const config = useConfig();
  const logger = useLogger('sign-in');
  const [storage, updateStorage] = useStorage();
  const { i18n } = useI18n();
  const dispatch = useDispatch();
  const api = useBridgeApi();
  const country = useSelector((state) => state.country);
  const [formData, setFormData] = useState<{ [key: string]: string }>({
    phone: config.PHONE_COUNTRY_CODE ?? getPhoneCode(country),
    password: '',
  });
  const [errors, setErrors] = useState(defaultFormData);
  const [loading, setLoading] = useState(false);
  const deviceSize = getDeviceSize();

  const [signIn, { data }] = useMutation(SIGN_IN_MUTATION, {
    variables: {
      companyId: config.COMPANY_ID,
      phone: formData.phone,
      password: formData.password,
    },
  });
  const [getUserData] = useLazyQuery<GetUser>(USER_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (props) => handleGetUserData(props),
  });

  const clearStoredToken = () => {
    updateStorage({ authToken: null, temporaryAuthToken: null });
  };

  const handleGetUserData = (userData: any) => {
    const token = data.login.token!;

    try {
      if (!userData?.user) {
        logger.warn('Sign in failed, no user data', { token });
        if (storage.authToken || storage.temporaryAuthToken) {
          clearStoredToken();
        }
        throw new Error('UNEXPECTED_ERROR_OCCURRED');
      }

      if (userData.user.activated) {
        updateStorage({ authToken: token });
        dispatch(setLoginScreen(''));
      } else {
        updateStorage({ temporaryAuthToken: token });
        dispatch(setLoginScreen('verify-account'));
      }
      api.vibrate('impactLight');
      setLoading(false);
      logger.info('Sign in succesful!', {
        user: userData?.user,
      });
    } catch (err: any) {
      console.log('successSignIn error', err);
      logger.warn('Sign in failed!', {
        description: err.message,
      });
      dispatch(
        setAlert({
          title: 'SignIn.Error.Failed',
          description: i18n(`Error.${err.message}`),
        }),
      );
      setLoading(false);
    }
  };

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = handleValidate();
    if (!isValid) {
      return;
    }

    setLoading(true);
    try {
      const response = await signIn();

      const { error, token } = response?.data?.login;

      if (!error && !token) {
        setLoading(false);
        return;
      }

      if (error || !token) {
        throw new Error(error);
      }
      updateStorage({ temporaryAuthToken: token });

      // Wait for dispatch to go through
      await new Promise((resolve) => {
        setTimeout(resolve, 1);
      });

      await getUserData();
    } catch (err: any) {
      logger.warn('handleSignIn error', { error: err });
      dispatch(
        setAlert({
          title: i18n('SignIn.Error.Failed'),
          description: i18n(`Error.${err.message}`),
        }),
      );
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    dispatch(setLoginScreen(''));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  // Triggered on blur
  const formatPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, phone: value.replace(/\s/g, '') }));
  };

  const handleValidate = () => {
    const messages: { [key: string]: string } = { ...errors };

    rules.forEach((field) => {
      let valid = false;

      if (field.name === 'phone') {
        const phoneNumber = parsePhoneNumberFromString(formData[field.name]);
        valid = Boolean(phoneNumber && phoneNumber.isValid());
      } else if (field.regexp) {
        valid = field.regexp.test(formData[field.name]);
      }
      messages[field.name] = valid ? '' : i18n(field.message);
    });

    setErrors((prev) => ({ ...prev, ...messages }));
    return Object.values(messages).every((el) => !el);
  };

  return (
    <>
      <Navbar title={i18n('Signin.Title')} onBack={handleGoBack} />
      <Wrapper>
        <Inner>
          <form onSubmit={handleSignIn}>
            <TextField
              label={i18n('Input.Phone')}
              placeholder={i18n('Input.Phone.Placeholder')}
              onChange={handleChange}
              onBlur={formatPhone}
              name="phone"
              value={formData.phone}
              type="tel"
              error={errors.phone}
            />
            <TextField
              label={i18n('Signin.Password')}
              placeholder={i18n('Signin.Password.Placeholder')}
              onChange={handleChange}
              value={formData.password}
              name="password"
              type="password"
              error={errors.password}
            />
            <ForgotPasswordLink
              to={'#'}
              onClick={() => {
                dispatch(setLoginScreen('forgot-password'));
              }}>
              {i18n('ForgotPassword.Text')}
            </ForgotPasswordLink>
            <Button
              text={i18n(loading ? 'General.Loading' : 'Signin.Title')}
              floating={deviceSize === DEVICE_SIZES.SMALL}
              width="100%"
              type="submit"
              disabled={loading}
            />
          </form>
        </Inner>
      </Wrapper>
    </>
  );
};

export default SignIn;
