import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { VenueAvailabilities, VenueAvailabilitiesVariables } from './__queries__';
import { useConfig } from 'Components/ConfigProvider';
import { DateTime } from 'luxon';
import useI18n from 'i18n';

const VENUE_QUERY = gql`
  query VenueAvailabilities($id: ID!) {
    venue: Venue(id: $id) {
      id
      name
      availabilities {
        id
        dayOfWeek
        timeRange {
          id
          start
          end
        }
      }
    }
  }
`;

enum DAY_OF_WEEK {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

const FALLBACK_CLOSED = {
  isOpen: false,
  from: '--',
  to: '--',
};

type Availability = {
  id: string;
  dayOfWeek: DAY_OF_WEEK;
  timeRange: {
    id: string;
    start: string;
    end: string;
    initialStart?: string;
    initialEnd?: string;
    isMidnight?: boolean;
  };
};

const useOpeningHours = (selectedVenueId?: string) => {
  const config = useConfig();
  const { i18n } = useI18n();
  const params = useParams<{ venue_id?: string }>();
  const venueId = selectedVenueId || params?.venue_id || '';
  const now = DateTime.now();

  const venueQuery = useQuery<VenueAvailabilities, VenueAvailabilitiesVariables>(VENUE_QUERY, {
    variables: {
      id: venueId,
    },
    skip: !venueId,
  });

  // The reason for this function is that it is difficult to check if a time is within timerange if the timerange exceeds midnight.
  // To solve this difficulty, the function splits the range // into new objects so that no individual range spans past midnight.
  // Example: start: 12:00 - 03:00 -> {start: 12:00, initialEnd: 03:00, end: 00:00}, {initialStart: 12:00, start: 00:00, end: 03:00}
  const splitOverMidnightAvailability = (availabilities: Availability[]) => {
    return availabilities.flatMap((availability) => {
      const startToUse = availability.timeRange.start;
      const endToUse = availability.timeRange.end;

      const start = DateTime.fromISO(startToUse);

      // If endTime is 00:00 we convert it to the last millisecond of the day for comparison
      const end =
        endToUse === '00:00' ? DateTime.fromISO('23:59:59:999') : DateTime.fromISO(endToUse);

      // If the end is 00:00 we don't need to split it into multiple parts
      if (endToUse === '00:00') {
        return [
          {
            ...availability,
            timeRange: {
              id: availability.timeRange.id,
              start: startToUse,
              end: endToUse,
            },
          } as Availability,
        ];
      }

      // If the availability does not span midnight, return it as is
      if (start < end) {
        return [
          {
            ...availability,
            timeRange: {
              id: availability.timeRange.id,
              start: startToUse,
              end: endToUse,
            },
          } as Availability,
        ];
      }

      // Split into two availabilities when spanning over midnight
      const nextDay = getNextDay(availability.dayOfWeek);

      return [
        {
          // First part: from start to 00:00 on the current day
          ...availability,
          timeRange: {
            id: availability.timeRange.id,
            start: startToUse,
            initialEnd: endToUse, // Initial end is saved for display purposes so we can display 12:00 - 03:00 instead of 12:00 - 00:00 and 00:00 - 03:00
            end: '00:00',
          },
        },
        {
          // Second part: from 00:00 to end on the next day
          ...availability,
          dayOfWeek: nextDay, // Change the day to the next day
          timeRange: {
            id: availability.timeRange.id,
            initialStart: startToUse, // Initial start is saved for display purposes so we can display 12:00 - 03:00 instead of 12:00 - 00:00 and 00:00 - 03:00
            start: '00:00',
            end: endToUse,
            isMidnight: true,
          },
        },
      ];
    });
  };

  // Helper function to get the next day of the week
  const getNextDay = (currentDay: DAY_OF_WEEK): DAY_OF_WEEK => {
    const daysOfWeek = Object.values(DAY_OF_WEEK);
    const currentIndex = daysOfWeek.indexOf(currentDay);
    if (currentIndex === -1) {
      throw new Error(`Invalid day of the week: ${currentDay}`);
    }

    const nextIndex = (currentIndex + 1) % daysOfWeek.length; // Wrap around if necessary (SUNDAY after SATURDAY)
    return daysOfWeek[nextIndex];
  };

  const availabilities = splitOverMidnightAvailability(
    venueQuery.data?.venue.availabilities ?? [],
  );

  const getCorrectAvailability = (availabilitiesList: Availability[], date: DateTime) => {
    for (const availability of availabilitiesList) {
      const { timeRange } = availability;

      if (timeRange.start === '00:00' && timeRange.end === '00:00') {
        return {
          isOpen: true,
          from: timeRange.start,
          to: timeRange.end,
        };
      }

      // Extracting time from the start and end strings
      const startTime = DateTime.fromISO(timeRange.start);
      // If endTime is 00:00 we convert it to the last second of the day for comparison
      const endTime =
        timeRange.end === '00:00' ? DateTime.fromISO('23:59:59') : DateTime.fromISO(timeRange.end);

      // Normalize the input date (just keep the time part for comparison, set year/month/day to 1970-01-01)
      const timeOnly = date.set({ year: 1970, month: 1, day: 1 });

      // Normalize start and end times for the same day (1970-01-01)
      const normalizedStartTime = startTime.set({ year: 1970, month: 1, day: 1 });
      const normalizedEndTime = endTime.set({ year: 1970, month: 1, day: 1 });

      if (normalizedStartTime < normalizedEndTime) {
        if (timeOnly >= normalizedStartTime && timeOnly <= normalizedEndTime) {
          return {
            isOpen: true,
            from: timeRange.initialStart ?? timeRange.start,
            to: timeRange.initialEnd ?? timeRange.end,
          };
        }
      }
    }
    return null;
  };

  const checkOpeningHoursForDate = (date?: Date) => {
    const timeForComparison = !date ? now : DateTime.fromJSDate(date);

    if (!timeForComparison || !timeForComparison.isValid) {
      return FALLBACK_CLOSED;
    }

    // Reason we use en-GB is because we save the day as english example WEDNESDAY
    const selectedDay = timeForComparison.setLocale('en-GB').weekdayLong.toUpperCase();
    const foundDayAvailabilities = availabilities.filter((a) => a.dayOfWeek === selectedDay);

    const availabilityResult = getCorrectAvailability(foundDayAvailabilities, timeForComparison);

    // If a matching availability was found, return it
    if (availabilityResult) {
      return availabilityResult;
    }

    // If the venue is closed the selected time we display the opening hours matching the weekday, we take the last one since the first is midnight opening hours if there are more than 1
    const lastDayAvailability = foundDayAvailabilities[foundDayAvailabilities.length - 1];
    return {
      isOpen: false,
      from:
        lastDayAvailability?.timeRange.initialStart ||
        lastDayAvailability?.timeRange.start ||
        '--',
      to: lastDayAvailability?.timeRange.initialEnd || lastDayAvailability?.timeRange.end || '--',
    };
  };

  const getNextOpeningTime = (): DateTime | null => {
    const currentTime = now;
    let foundOpeningTime: DateTime | null = null;

    for (let i = 0; i < WEEK_DAYS.length; i++) {
      const dayIndex = (currentTime.weekday + i - 1) % WEEK_DAYS.length; // Offset by current day and wrap around
      const dayOfWeek = WEEK_DAYS[dayIndex];

      const foundDayAvailabilities = availabilities.filter(
        (availability) => availability.dayOfWeek === dayOfWeek,
      );

      for (const availability of foundDayAvailabilities) {
        const openingTime = DateTime.fromISO(availability.timeRange.start).set({
          year: currentTime.year,
          month: currentTime.month,
          day: currentTime.day + i,
        });

        // If it's today and opening time is later than now, or if it's a future day
        if (openingTime > currentTime) {
          foundOpeningTime = openingTime;
          break;
        }
      }

      if (foundOpeningTime) break; // Exit loop if we found an opening time
    }

    return foundOpeningTime;
  };

  const WEEK_DAYS = [
    DAY_OF_WEEK.MONDAY,
    DAY_OF_WEEK.TUESDAY,
    DAY_OF_WEEK.WEDNESDAY,
    DAY_OF_WEEK.THURSDAY,
    DAY_OF_WEEK.FRIDAY,
    DAY_OF_WEEK.SATURDAY,
    DAY_OF_WEEK.SUNDAY,
  ];

  const openingHoursList = WEEK_DAYS.map((day) => {
    const foundDayAvailabilities = venueQuery.data?.venue.availabilities.filter(
      (a) => a.dayOfWeek === day,
    );
    const dayAvailability = foundDayAvailabilities && foundDayAvailabilities[0];

    const start = dayAvailability?.timeRange.start;
    const end = dayAvailability?.timeRange.end;

    return {
      day: i18n('Weekday.' + day),
      openingHours: start && end ? start + ' - ' + end : i18n('Store.Closed'),
    };
  });

  return {
    today: checkOpeningHoursForDate(),
    disablePreOrder: config.DISABLE_PICKUP_TIME,
    loading: !venueQuery.data || venueQuery.loading,
    openingHoursList: openingHoursList,
    checkOpeningHoursForDate,
    getNextOpeningTime,
  };
};

export default useOpeningHours;
