import { FC, useState } from 'react';
import { startCase } from 'lodash';
import { i18nProps } from 'Types';
import { useConfig } from 'Components/ConfigProvider';
import useOpeningHours from 'Hooks/useOpeningHours';
import useI18n from 'i18n';
import Button from 'Components/Button';
import { useLogger, useSelector } from 'Hooks';
import { useStorage } from 'Components/Storage';
import { useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PreOrderTimePicker from 'Components/PreOrderTimePicker';
import Modal from 'Components/Modal';

const Wrapper = styled.div`
  padding: 0 24px;
  width: 100%;
`;

const InfoText = styled.p`
  text-align: center;
  margin-bottom: 8px;
`;

enum ServiceType {
  EAT_HERE = 'EAT_HERE',
  TAKE_AWAY = 'TAKE_AWAY',
}

type ServiceTypeObj = {
  id: string;
  name: string;
  description: string;
};

const getServiceType = (serviceType: string) => {
  switch (serviceType) {
    case 'EAT_HERE':
      return ServiceType.EAT_HERE;
    case 'TAKE_AWAY':
      return ServiceType.TAKE_AWAY;
    default:
      return null;
  }
};

export const parseServiceType = (type: string, i18n: i18nProps): ServiceTypeObj => {
  switch (type) {
    case 'TAKE_AWAY':
      return {
        id: 'TAKE_AWAY',
        name: i18n('StoreList.Modal.TakeAway'),
        description: i18n('StoreList.Modal.TakeAway.Text'),
      };

    case 'EAT_HERE':
      return {
        id: 'EAT_HERE',
        name: i18n('StoreList.Modal.EatHere'),
        description: i18n('StoreList.Modal.EatHere.Text'),
      };
    default:
      return {
        id: type,
        name: startCase(type),
        description: '',
      };
  }
};

interface Props {
  venueId: string;
}

const ServiceTypePicker: FC<Props> = ({ venueId }) => {
  const { i18n } = useI18n();
  const logger = useLogger('ServiceTypePicker');
  const config = useConfig();
  const [storage, updateStorage] = useStorage();
  const history = useHistory();
  const shoppingCartApi = useShoppingCartApi();
  const userId = useSelector((state) => state.user?.id);
  const [showPreOrderModal, setShowPreOrderModal] = useState(false);
  const { today, disablePreOrder } = useOpeningHours(venueId);

  const handleServiceTypeClick = (serviceType: string) => {
    const type = getServiceType(serviceType);

    if (!type) {
      logger.error('Invalid service type', { serviceType });
      return;
    }

    const id = shoppingCartApi.createCart(venueId, type, null, false);

    logger.info('Cart created', {
      cartId: id,
      venueId,
      serviceType,
      userId: userId ?? 'anonymous',
    });

    updateStorage({
      serviceType,
      qrOrder: false,
    });

    history.push({ pathname: `/order/${venueId}` });
  };

  return (
    <Wrapper>
      {!today.isOpen && (
        <InfoText>
          {
            disablePreOrder && storage.hasTimeControlledMenus // Since they have time controlled menus and disallow pre-ordering, we show that it is closed and they need to come back later (We cannot know which menu to show)
              ? i18n('Store.ClosedNoPreOrderBlocked')
              : disablePreOrder
              ? i18n('Store.ClosedNoPreOrder') // If pre-order is disabled but they don't have time controlled menus, we know which menu to show so they are allowed to look around at the menu
              : i18n('Store.ClosedPreOrder') // If pre-order is allowed, we show that they can make a pre-order but hide the take-away and eat-here buttons
          }
        </InfoText>
      )}

      {/* If we disable preorder and no time controlled menus we still allow looking at the menus */}
      {(today.isOpen || (!storage.hasTimeControlledMenus && disablePreOrder)) && (
        <>
          {config.ALLOWED_SERVICE_TYPES.map((type) => {
            const serviceType = parseServiceType(type, i18n);
            return (
              <Button
                key={serviceType.id}
                text={serviceType.name}
                handleClick={() => handleServiceTypeClick(serviceType.id)}
                width="100%"
              />
            );
          })}
        </>
      )}

      {!disablePreOrder && (
        <Button
          text={i18n('Order.PreOrder')}
          handleClick={() => setShowPreOrderModal(true)}
          width="100%"
        />
      )}

      <Modal isShowing={showPreOrderModal} hide={() => setShowPreOrderModal(false)} backdropClose>
        <PreOrderTimePicker venueId={venueId} />
      </Modal>
    </Wrapper>
  );
};

export default ServiceTypePicker;
